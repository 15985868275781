import { PersonalizedAccountHero } from 'components/src/AccountHero'
import { ComponentConfig } from 'components/src/utils/ComponentOverridesContext'

import BreadcrumbIcon from './components/BreadcrumbIcon'
import CartItemContainer from './components/cart/CartItemContainer'
import CartItemImage from './components/cart/CartItemImage'
import CartItemInfoContainer from './components/cart/CartItemInfoContainer/CartItemInfoContainer'
import Logo from './components/Logo'
import MemberCardTopBar from './components/MemberCardTopBar'
import NotFoundBackgroundImages from './components/NotFoundPage/NotFoundBackgroundImages'
import PickupPointIcon from './components/PickupPointIcon'
import PostalAddressIcon from './components/PostalAddressIcon'
import ProductInfoHeader from './components/ProductInfo/ProductInfoHeader'
import SuccessPageBackgroundImages from './components/SuccessPage/SuccessPageBackgroundImages'
import SuccessPageButtons from './components/SuccessPage/SuccessPageButtons'
import UserDetailsIcon from './components/UserDetailsIcon'
import VoucherEuroIcon from './components/VoucherEuroIcon/VoucherEuroIcon'
import ThemeOverrides from './theme/ThemeOverrides'

const componentsConfig: ComponentConfig = {
    CartItemContainer,
    CartItemImage,
    CartItemInfoContainer,
    AccountHero: PersonalizedAccountHero,
    BreadcrumbIcon,
    Logo,
    NotFoundBackgroundImages,
    PickupPointIcon,
    PostalAddressIcon,
    ProductInfoHeader,
    SuccessPageBackgroundImages,
    SuccessPageButtons,
    ThemeOverrides,
    VoucherEuroIcon,
    MemberCardTopBar,
    UserDetailsIcon,
}

export default componentsConfig
