import { Trans } from '@lingui/macro'
import Logo from 'components/src/core/Logo'
import Heading from 'components/src/typography/Heading'

import styles from './MemberCardTopBar.module.scss'

// eslint-disable-next-line react/display-name
export default () => (
    <div className={styles.wrapper}>
        <Logo className={styles.logo} />
        <Heading variant="h3" element="h3" className={styles.subheader}>
            <Trans id="user.membercard.logo.subheader">Just Brands</Trans>
        </Heading>
    </div>
)
