import { css, Global } from '@emotion/react'
import { varKeys as colorVarKeys } from 'components/src/theme/colors'
import { varKeys as typographyVarKeys } from 'components/src/theme/typography'
import { varKeys as utilVarKeys } from 'components/src/theme/utils'

const ThemeOverrides = () => (
    <Global
        styles={css`
            :root {
                ${utilVarKeys.topbarMenuOffset}: 56px;
                ${colorVarKeys.grayDark}: #3a3d35;
                ${colorVarKeys.primary}: #384649;
                ${colorVarKeys.primaryExtra}: #6e7a7b;

                ${colorVarKeys.secondary}: transparent;
                ${colorVarKeys.secondaryExtra}: #65594d;

                ${colorVarKeys.badgeBg}: #c66f07;

                --color-highlight-cta: #3a3d35;
                --color-highlight-cta-extra: #56573d;

                ${colorVarKeys.onBackground}: #3a3d35;
                ${colorVarKeys.darkBackground}: #3a3d35;

                ${typographyVarKeys.fonts.primary}: 'Roboto Slab', Helvetica,
                    Arial, sans-serif;
                ${typographyVarKeys.fonts.secondary}: 'Roboto Slab', Helvetica,
                    Arial, sans-serif;

                ${colorVarKeys.footerTopBackground}: var(
                    ${colorVarKeys.primary}
                );
                ${colorVarKeys.footerDivider}: var(--color-white);

                ${colorVarKeys.footerMainBackground}: var(
                    ${colorVarKeys.primary}
                );
                ${colorVarKeys.promoBannerBackground}: var(
                    ${colorVarKeys.sale}
                );

                --lowest-price-font-weight: 300;

                --membercard-bg-color: var(${colorVarKeys.primary});
                --membercard-logo-color: var(${colorVarKeys.primaryExtra});
                --member-card-logo-wrap: row wrap;
                --membercard-bar-color: var(${colorVarKeys.grayDark});

                --color-category-footer-links: var(--color-primary-extra);
            }
        `}
    />
)

export default ThemeOverrides
